<template>
  <div class="CurrencyIcon">
    <div class="CurrencyIcon_icon text-white">
      <SvgIcon v-if="type === 'svg'"
        :icon="currency"
      ></SvgIcon>
      <img v-else-if="type === 'image'" :src="getImg" alt="">
    </div>
    <div class="flex items-center text-blue-300 dark:text-white cursor-pointer text-[12px] leading-[14px] uppercase 2xl:text-[14px] leading-[16px]">{{ currency }}</div>
  </div>
</template>

<script>
import SvgIcon from "@/components/_common/SvgIcon";
export default {
  props: {
    currency: {
      type: String,
      required: true,
      default: '',
    },
  },
  components: {
    SvgIcon,
  },
  computed: {
    type() {
      return this.currency === 'UACB-NOV22' ? 'image' : 'svg';
    },
    getImg() {
      try {
        return require(`@/assets/icons/${this.currency}.svg`);
      } catch (error) {
        return '';
      }
    },
  },
};
</script>

<style lang="scss">
  @import './CurrencyIcon.scss';
</style>

