<template>
  <div 
    class="ProvidersTable_Filter"
    :class="{
      'ProvidersTable_Filter--open': visible,
    }"
  >
    <Button 
      class="btn btn-small btn-bright btn-bright-small min-w-full md:min-w-[100px] btn"
      text="Filter"
      @click="toggleVisible"
    >
      <template #icon>
        <SvgIcon
          class="mr-[5px]"
          icon="iconFilter"
        ></SvgIcon>
      </template>
    </Button>
    <div class="ProvidersTable_FilterDropdown">
      <div class="ProvidersTable_FilterDropdownHead">
        <div class="ProvidersTable_FilterDropdownTitle">Filter</div>
        <div class="ProvidersTable_FilterDropdownDone" @click="setVisible(false)">Done</div>
      </div>
      <div class="ProvidersTable_FilterDropdownList">
        <div 
          class="ProvidersTable_FilterDropdownListItem"
          :class="{
            'ProvidersTable_FilterDropdownListItem--open': filter.visible,
          }"
          @click="toggelFilterVisible(filter)"
          v-for="filter in filters"
          v-bind:key="filter.id"
        >
          <div class="ProvidersTable_FilterDropdownListItemTitle">{{filter.title}}</div>
          <div class="ProvidersTable_FilterDropdownListItemArrow">
            <iconChevron />
          </div>
          <Select
            class="ProvidersTable_FilterDropdownListItemSelect"
            :items="filter.options"
            :value="filter.value"
            :name="filter.name"
            @change="changeFilter"
            componentName="VAutocomplete"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Select from '@/components/_common/FormElements/Select/Select';
import Button from '@/components/_common/FormElements/Button/Button';
import { iconChevron } from '@/assets/icons/arrows';
import { iconFilter } from '@/assets/icons/common/';
import { mapGetters } from 'vuex';
import SvgIcon from "@/components/_common/SvgIcon";
export default {
  components: {
    Select,
    Button,
    SvgIcon,
    iconChevron,
    iconFilter,
  },
  data() {
    return {
      visible: false,
      filters: [ 
        {
          visible: false,
          name: 'paymentMethod',
          title: 'Payment method',
          value: 'All',
          // options: ['All', 'CARD', 'BEP20'],
        },
        {
          visible: false,
          name: 'paymentProvider',
          title: 'Payment provider',
          value: 'All',
          // options: ['All', 'BILLLINE', 'CRYPTO_NODE'],
          options: [],
        },
      ],
    };
  },
  computed: {
    ...mapGetters('providers', ['userProviders']),
    /*paymentProviders(){
      let paymentProviders = []
      console.log(this.userProviders)
      return paymentProviders
    }*/
  },
  mounted() {
    this.setOptions()
  },
  methods: {
    setOptions(){
      // console.log(this.userProviders)
      let paymentProviders = ['All']
      let paymentMethods = ['All']
      for (let index in this.userProviders) {
        let paymentProvider = this.userProviders[index].provider
        if (paymentProviders.indexOf(paymentProvider) == -1) {
          paymentProviders.push(paymentProvider)
        }
        // console.log(this.userProviders[index].payment_methods)
        for (let method in this.userProviders[index].payment_methods) {
          if (paymentMethods.indexOf(method) == -1) {
            paymentMethods.push(method)
          }
        }
      }
      this.filters.find(filter=>filter.name=='paymentProvider').options = paymentProviders
      this.filters.find(filter=>filter.name=='paymentMethod').options = paymentMethods
    },
    changeFilter(event) {
      this.filters.find(filter=>filter.name == event.field).value = event.value
      this.$emit('changeFilters', this.filters)
      this.setVisible(false)
    },
    toggelFilterVisible(filter){
      filter.visible = true
    },
    toggleVisible() {
      this.visible = !this.visible
    },
    setVisible(visible) {
      this.visible = visible
    },
  },
};
</script>

<style lang="scss">
  @import './ProvidersTableFilter.scss';
</style>