<template>
  <div class="ProvidersTable_Body">
    <div
      class="ProvidersTable_Row"
      :class="{
        'ProvidersTable_Row--disable': !provider.is_active,
      }"
      v-for="provider in providers"
      v-bind:key="provider.id"
    >
      <div class="ProvidersTable_RowHead">
        <div class="ProvidersTable_RowSubHead cursor-pointer" @click="$router.push({
            name: 'providers.provider',
            params: { 
              providerId: provider.id,
            },
          })">
          <div class="ProvidersTable_RowHeadIcon">
            <img :src="require(`@/assets/img/acquiring/providers/${provider.iconName}`)" alt="Provider Icon" />
          </div>
          <div class="ProvidersTable_RowHeadName">{{ provider.name }}</div>
          <div class="ProvidersTable_RowHeadSubName">({{ provider.provider }})</div>
          <div class="ProvidersTable_RowHeadId">id:{{ provider.id }}</div>
        </div>
        <div class="ProvidersTable_RowHeadSpacer"></div>
        <div class="ProvidersTable_RowAddRout" @click="$router.push({
            name: 'providers.provider.route',
            params: { 
              providerId: provider.id,
            },
          })">
          <span>Add Route</span>
          <div class="ProvidersTable_RowAddRoutCircle">
            <SvgIcon
              icon="iconPlus"
            ></SvgIcon>
          </div>
        </div>
        <div class="ProvidersTable_RowActivator" @click="toggleProviderEnable(provider)">
          <span></span>
        </div>
      </div>
      <div class="ProvidersTable_RowBody" v-if="provider.payment_routes_formatted.length > 0">
        <div class="ProvidersTable_SubRow ProvidersTable_SubRow--titles">
          <div class="ProvidersTable_SubRowTitle">Service</div>
          <div class="ProvidersTable_SubRowTitle">Account Type</div>
          <div class="ProvidersTable_SubRowTitle">Currency</div>
          <div class="ProvidersTable_SubRowTitle">Activate</div>
        </div>
        <template v-for="(routerGroup, index) in provider.payment_routes_formatted">
          <div
            class="ProvidersTable_SubRow ProvidersTable_SubRow--main"
            :class="{
              'ProvidersTable_SubRow--allRoutes': index == 0,
              'ProvidersTable_SubRow--visible': routerGroup.visible && index != 0,
              'ProvidersTable_SubRow--disable': !router.is_active,
              'ProvidersTable_SubRow--light': routerGroup.visible,
            }"
            v-for="(router, index) in routerGroup.routers"
            :key="router.id"
          >
            <div class="ProvidersTable_SubRowTitle">Service</div>
            <div class="ProvidersTable_SubRowTitle">Account Type</div>
            <div
              class="ProvidersTable_SubRowRouterCell"
            >
              <div class="ProvidersTable_SubRowRouter">
                <div class="ProvidersTable_SubRowRouterIcon">
<!--                  <component :is="`icon${operationTypeFormatter(router.operation_type)}`" fillColor="#fff" />-->
                  <SvgIcon :icon="`icon${operationTypeFormatter(router.operation_type)}`"></SvgIcon>
                </div>
                <div 
                  class="ProvidersTable_SubRowRouterName"
                  @click="handlerRouterNameClick({router, routerGroup, provider})"
                >
                  {{ router.name }}
                  <span
                    class="round-arrow-btn ml-[10px]" v-if="index === 0"
                  >
                    <SvgIcon
                      class="w-[7px] h-[7px] rotate-180"
                      icon="iconChevron"
                    ></SvgIcon>
                  </span>
                </div>
              </div>
            </div>
            <div class="ProvidersTable_SubRowText">{{ router.text }}</div>
            <div class="ProvidersTable_SubRowTitle">Currency</div>
            <div class="ProvidersTable_SubRowTitle">Activate</div>
            <div class="ProvidersTable_SubRowCurrenciesCell">
              <div class="ProvidersTable_SubRowCurrencies">
                <CurrencyIcon
                  v-for="currency in router.currencies.slice(0, 2)"
                  :key="currency"
                  :currency="currency"
                />
                <div
                  class="ProvidersTable_SubRowCurrenciesInfo"
                  v-if="router.currencies.length > 2"
                >
                  <InfoPseudoIcon />
                  <div class="ProvidersTable_SubRowCurrenciesTooltip">
                    <CurrencyIcon
                      v-for="currency in router.currencies.slice(-1 * (router.currencies.length - 2))"
                      :key="currency"
                      :currency="currency"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="index == 0" class="ProvidersTable_SubRowButtonCell">
              <div class="ProvidersTable_SubRowButton" @click="toggleRouterGroupVisible(routerGroup)">
                <template v-if="!routerGroup.visible">
                  <SvgIcon
                    icon="iconShow"
                  ></SvgIcon>
                  <span>View</span>
                </template>
                <template v-else>
                  <SvgIcon
                    icon="iconHide"
                  ></SvgIcon>
                  <span>Hide</span>
                </template>
              </div>
            </div>
            <div v-else class="ProvidersTable_SubRowButtonCell">
              <div class="ProvidersTable_SubRowButtonWrap">
                <div 
                  class="ProvidersTable_SubRowButton" 
                  @click="$router.push({
                    name: 'providers.provider.route',
                    params: {
                      providerId: provider.id,
                      routeId: router.id,
                    },
                  })"
                >
                  <SvgIcon
                    icon="iconEdit2"
                  ></SvgIcon>
                  <span>Edit</span>
                </div>
                <div 
                  class="ProvidersTable_SubRowActivator" 
                  @click="toggleRouterEnable({router, provider})"
                >
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="ProvidersTable_SubRowSpoilerButton" 
            v-if="provider.payment_routes_formatted[index].visible" 
            v-bind:key="index"
            @click="toggleRouterGroupVisible(provider.payment_routes_formatted[index])"
          >
            <div class="ProvidersTable_SubRowSpoilerButtonLabel">
              <SvgIcon
                icon="iconHide"
              ></SvgIcon>
              <span>Hide</span>
            </div>
            <div class="ProvidersTable_SubRowSpoilerButtonArrow">
              <SvgIcon
                icon="arrowTop"
              ></SvgIcon>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="ProvidersTable_BodyEmpty" v-if="providers.length == 0">
      No providers found
    </div>
  </div>
</template>

<script>
import CurrencyIcon from '@/components/_common/CurrencyIcon/CurrencyIcon';
import SvgIcon from "@/components/_common/SvgIcon";
import { InfoPseudoIcon } from '@/assets/icons/arrows';
import { mapActions, mapGetters } from 'vuex';
import { providersIcons } from '@/views/Application/Account/Providers/_helpers/';
export default {
  components: {
    SvgIcon,
    CurrencyIcon,
    InfoPseudoIcon,
  },
  data() {
    return {
      providersIcons,
      routersIdVisible: [],
      providerOffsetCounter: 0,
      providerOnPageCounter: 0,
    };
  },
  props: {
    providerNameSearch: {
      type: String,
      default: '',
    },
    filters: {
      type: Array,
      default: [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    providerOnPageNumber: {
      type: Number,
      default: 10,
    },
    providerPagesNumber: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters('providers', ['userProviders']),
    providerOffsetNumber() {
      return (this.currentPage - 1) * this.providerOnPageNumber
    },
    providers() {
      let providers = [];
      this.providerOffsetCounter = 0
      this.providerOnPageCounter = 0
      for (let index in this.userProviders) {
        // let provider = this.userProviders[index]
        let provider = JSON.parse(JSON.stringify(this.userProviders[index]))
        provider.iconName = this.providersIcons[provider.provider] || 'billine.svg'
        provider.payment_routes_formatted = [
          {
            operation_type: 'DEPOSIT',
            visible: false,
            id: `${provider.id}D`,
            routers: [],
          },
          {
            operation_type: 'WITHDRAWAL',
            visible: false,
            id: `${provider.id}W`,
            routers: [],
          },
        ]

        provider.payment_routes.forEach(routerSource=>{
          const operationTypeCapitalized = this.operationTypeFormatter(routerSource.operation_type);
          routerSource = this.filteringRouter(routerSource)
          if (routerSource) {
            provider.payment_routes_formatted.
              find(provider=>provider.operation_type == routerSource.operation_type).routers.
              push({
                text: `${operationTypeCapitalized} account`,
                ...routerSource,
              })
          }
        })

        provider.payment_routes_formatted = provider.payment_routes_formatted.filter(providerGroup=>providerGroup.routers.length>0)

        provider.payment_routes_formatted.forEach(providerGroup => {
          const operationTypeCapitalized = this.operationTypeFormatter(providerGroup.operation_type)
          let summingRouter = {
            name: `${operationTypeCapitalized} account [${providerGroup.routers.length}]`,
            text: `${operationTypeCapitalized} account`,
            currencies: [],
            id: `${provider.id}${providerGroup.operation_type.charAt(0)}`,
            operation_type: providerGroup.operation_type,
            routerType: 'spoiler',
          }
          providerGroup.routers.forEach(router => {
            router.currencies.forEach(currency=>{
              if (summingRouter.currencies.indexOf(currency) == -1) {
                summingRouter.currencies.push(currency)
              }
            })
          })
          providerGroup.routers.unshift(summingRouter)
          if (this.routersIdVisible.indexOf(providerGroup.id) !== -1) {
            providerGroup.visible = true
          }
        })
        if (provider = this.filteringProvider(provider)) providers.push(provider)
      }
      this.setProviderPagesNumber()
      return providers
    }
  },
  methods: {
    ...mapActions('providers', ['apiUpdateProvider', 'apiUpdateRoute']),
    setProviderPagesNumber() {
      let providerPagesNumber = Math.ceil(this.providerOffsetCounter / this.providerOnPageNumber)
      this.$emit('setProviderPagesNumber', providerPagesNumber)
    },
    handlerRouterNameClick({router, routerGroup, provider}) {
      if (router.routerType == 'spoiler') {
        this.toggleRouterGroupVisible(routerGroup)
      } else {
        this.$router.push({
          name: 'providers.provider.route',
          params: {
            providerId: provider.id,
            routeId: router.id,
          },
        })
      }
    },
    operationTypeFormatter(operationType){
      if (operationType) {
        operationType = operationType.charAt(0).toUpperCase() + operationType.slice(1).toLowerCase()
        return operationType
      } else {
        return '';
      }
    },
    filteringRouter(router) {
      router = this.filteringRouterByPaymentMethod(router)
      if (!router) return false
      return router
    },
    filteringRouterByPaymentMethod(router) {
      let filterPaymentMethod = this.filters.find(filter => filter.name == 'paymentMethod')?.value || 'All'
      if (filterPaymentMethod == 'All') return router
      if (router.payment_method == filterPaymentMethod) {
        return router
      } else {
        return false
      }
    },
    filteringProvider(provider) {
      provider = this.filteringProviderBySearch(provider)
      if (!provider) return false
      provider = this.filteringProviderByPaymentProvider(provider)
      if (!provider) return false
      provider = this.filteringProviderByPagination({
        provider,
      })
      return provider
    },
    filteringProviderByPagination({
      provider,
    }) {
      this.providerOffsetCounter++
      if (this.providerOffsetCounter > this.providerOffsetNumber 
        && this.providerOnPageCounter < this.providerOnPageNumber) {
        this.providerOnPageCounter++
        return provider
      } else {
        return false
      }
    },
    filteringProviderBySearch(provider) {
      let isProviderName = provider.name.toLowerCase().indexOf(this.providerNameSearch.toLowerCase()) !== -1
      let isProviderTypeName = provider.provider.toLowerCase().indexOf(this.providerNameSearch.toLowerCase()) !== -1
      if (isProviderName || isProviderTypeName) {
        return provider;
      } else {
        return false;
      }
    },
    filteringProviderByPaymentProvider(provider) {
      let filterPaymentProvider = this.filters.find(filter => filter.name == 'paymentProvider')?.value || 'All'
      if (filterPaymentProvider == 'All') return provider
      if ( provider.provider.toLowerCase() == filterPaymentProvider.toLowerCase() ) {
        return provider
      } else {
        return false
      }
    },
    filteringProviderByPaymentProviderName(provider) {
      let filterPaymentProvider = this.filters.find(filter => filter.name == 'paymentProvider')?.value || 'All'
      if (filterPaymentProvider == 'All') return provider
      if ( provider.name.toLowerCase() == filterPaymentProvider.toLowerCase() ) {
        return provider
      } else {
        return false
      }
    },
    toggleProviderEnable(provider) {
      this.apiUpdateProvider({payment_account_id: provider.id, is_active: !provider.is_active})
    },
    toggleRouterGroupVisible(routerGroup) {
      let indexId = this.routersIdVisible.indexOf(routerGroup.id)
      if (indexId == -1) {
        this.routersIdVisible.push(routerGroup.id)
      } else {
        this.routersIdVisible.splice(indexId, 1)
      }
    },
    toggleRouterEnable({router, provider}) {
      const object = {
        is_active: !router.is_active,
        payment_route_id: router.id,
        payment_account_id: provider.id,
      }
      this.apiUpdateRoute(object).then(() => {});
    },
    changeProvidersVisible(visible) {
      this.routersIdVisible = []
      if (visible) {
        this.providers.forEach(provider => {
          provider.payment_routes_formatted.forEach(router => {
            this.routersIdVisible.push(router.id)
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import './ProvidersTableBody.scss';
</style>