var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.providerPagesNumber > 1)?_c('div',{staticClass:"ProvidersTable_Pagination"},[_c('div',{staticClass:"ProvidersTable_PaginationTitle"},[_vm._v("Page "+_vm._s(_vm.currentPage)+" of "+_vm._s(_vm.providerPagesNumber))]),_c('div',{staticClass:"ProvidersTable_PaginationList"},[(_vm.currentPage > 1)?_c('div',{class:{
          'ProvidersTable_PaginationItem': true,
          'ProvidersTable_PaginationItem--arrow': true,
          'ProvidersTable_PaginationItem--prev': true,
        },on:{"click":_vm.prevPage}},[_c('i',{staticClass:"material-icons",attrs:{"aria-hidden":"true"}},[_vm._v("chevron_left")])]):_vm._e(),_vm._l((_vm.providerPagesNumber),function(index){return _c('div',{key:index,class:{
          'ProvidersTable_PaginationItem': true, 
          'ProvidersTable_PaginationItem--current': index == _vm.currentPage,
        },on:{"click":function($event){return _vm.setCurrentPage(index)}}},[_vm._v(" "+_vm._s(index)+" ")])}),(_vm.currentPage < _vm.providerPagesNumber)?_c('div',{class:{
          'ProvidersTable_PaginationItem': true,
          'ProvidersTable_PaginationItem--arrow': true,
          'ProvidersTable_PaginationItem--next': true,
        },on:{"click":_vm.nextPage}},[_c('i',{staticClass:"material-icons",attrs:{"aria-hidden":"true"}},[_vm._v("chevron_right")])]):_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }