<template>
  <div class="flex flex-col text-left h-full">
    <ProvidersTableHeader 
      @changeProvidersVisible="changeProvidersVisible"
      @search="search"
      @changeFilters="changeFilters"
    />
    <ProvidersTableBody 
      ref="ProvidersTableBody"
      :providerNameSearch="providerNameSearch"
      :filters="filters"
      :currentPage="currentPage"
      :providerOnPageNumber="providerOnPageNumber"
      @setProviderPagesNumber="setProviderPagesNumber"
    />
    <ProvidersPagination 
      ref="ProvidersPagination"
      :providerPagesNumber="providerPagesNumber"
      :currentPage="currentPage"
      @setCurrentPage="setCurrentPage"
    />
  </div>
</template>

<script>
import ProvidersTableHeader from './ProvidersTableHeader/ProvidersTableHeader';
import ProvidersTableBody from './ProvidersTableBody/ProvidersTableBody';
import ProvidersPagination from './ProvidersPagination/ProvidersPagination';
export default {
  components: {
    ProvidersPagination,
    ProvidersTableBody,
    ProvidersTableHeader,
  },
  data() {
    return {
      providerNameSearch: '',
      filters: [],
      currentPage: 1,
      providerOnPageNumber: 10,
      providerPagesNumber: 0,      
    };
  },
  methods: {
    changeProvidersVisible(visible){
      this.$refs.ProvidersTableBody.changeProvidersVisible(visible);
    },
    search(name) {
      this.providerNameSearch = name
    },
    changeFilters(filters) {
      this.filters = filters
      this.currentPage = 1
    },
    setProviderPagesNumber(providerPagesNumber) {
      this.providerPagesNumber = providerPagesNumber
    },
    setCurrentPage(currentPage) {
      this.currentPage = currentPage
    },
  }
};
</script>