<template>
  <div class="ProvidersTable_Pagination" v-if="providerPagesNumber > 1">
      <div class="ProvidersTable_PaginationTitle">Page {{ currentPage }} of {{ providerPagesNumber }}</div>
      <div class="ProvidersTable_PaginationList">
        <div 
          :class="{
            'ProvidersTable_PaginationItem': true,
            'ProvidersTable_PaginationItem--arrow': true,
            'ProvidersTable_PaginationItem--prev': true,
          }" 
          v-if="currentPage > 1"
          @click="prevPage"
        >
          <i aria-hidden="true" class="material-icons">chevron_left</i>
        </div>
        <div 
          v-for="index in providerPagesNumber" 
          v-bind:key="index" 
          :class="{
            'ProvidersTable_PaginationItem': true, 
            'ProvidersTable_PaginationItem--current': index == currentPage,
          }"
          @click="setCurrentPage(index)"
        >
          {{index}}
        </div>
        <!-- <div class="ProvidersTable_PaginationItem">3</div> -->
        <!-- <div class="ProvidersTable_PaginationItem ProvidersTable_PaginationItem--dots">...</div> -->
        <div 
          :class="{
            'ProvidersTable_PaginationItem': true,
            'ProvidersTable_PaginationItem--arrow': true,
            'ProvidersTable_PaginationItem--next': true,
          }"
          v-if="currentPage < providerPagesNumber"
          @click="nextPage"
        >
          <i aria-hidden="true" class="material-icons">chevron_right</i>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  components: {},
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    providerPagesNumber: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      // providerPagesNumber: 0,
    };
  },
  methods: {
    nextPage() {
      const nextPage = this.currentPage + 1
      this.setCurrentPage(nextPage)
      // console.log("next page")
    },
    prevPage() {
      const prevPage = this.currentPage - 1
      this.setCurrentPage(prevPage)
    },
    setCurrentPage(currentPage) {
      this.$emit('setCurrentPage', currentPage)
      // console.log(currentPage)
    },
  }
};
</script>

<style lang="scss">
@import './ProvidersPagination.scss';
</style>