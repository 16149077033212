<template>
  <section class="Providers_root">
    <AppSection title="Dashboard">
      <div slot="tollbar" class="Providers_btn">
        <Button 
          class="btn btn-small btn-bright leading-[40px] min-w-[122px]"
          text="Add provider" 
          size="large" 
          brightBtn 
          @click="$router.push({name: 'providers.provider'})" 
        >
          <template #icon>
            <iconPieChart
              class="mr-[10px]"
              fillColor="#2D4D70" />
          </template>
        </Button>
      </div>
      <div class="Providers_wrapper" slot="content">
        <ProvidersEmpty v-if="isEmpty(this.userProviders)" />
        <div class="Providers_container" v-else >
          <ProvidersTable />
        </div>
      </div>
    </AppSection>
  </section>
</template>

<script>
import { isEmpty } from 'ramda';
import AppSection from '@/components/_common/AppSection/AppSection';
import Button from '@/components/_common/FormElements/Button/Button';
import { mapActions, mapGetters } from 'vuex';
import ProvidersEmpty from '../ProvidersEmpty/ProvidersEmpty';
import ProvidersTable from './ProvidersTable/ProvidersTable';
import { iconPieChart } from '@/assets/icons/common/';

export default {
  components: {
    Button,
    AppSection,
    ProvidersEmpty,
    ProvidersTable,
    iconPieChart,
  },
  data: () => ({
    loading: false,
    isEmpty,
  }),
  computed: {
    ...mapGetters('providers', ['userProviders']),
  },
  methods: {
    ...mapActions('providers', ['apiGetUserProviders']),
    loadingHandler(loadingStatus) {
      loadingStatus ? (this.loading = true) : (this.loading = false);
    },
  },
  mounted() {
    this.apiGetUserProviders();
  },
};
</script>

<style lang="scss">
@import './Dashboard.scss';
</style>
