<template>
  <div class="flex flex-wrap mb-[12px] z-[999] relative justify-between">
    <div class="row-action-elements w-full  md:flex md:justify-between mb-[10px] md:mb-0">
      <input
        class="mb-[14px] md:mb-0 md:order-1 w-full md:w-[124px] border-b-[1px] border-[#a6aebf]/[0.21] outline-none text-inherit text-[12px] leading-[14px] py-[3px] pb-[3px] pl-[15px] bg-icon-search bg-[length:12px_28px] bg-[0% 50%] bg-no-repeat"
        placeholder="Search"
        @input="search"
        v-model="name"
      />
      <ProvidersTableFilter
        @changeFilters="changeFilters"
      />
    </div>
    <div class="inline-flex flex-wrap items-end w-full md:w-auto">
      <Button
        class="min-w-[49%] md:min-w-[84px] mr-[5px] btn-default-small flex flex-wrap z-[999] relative btn-small btn-default-yellow"
        text="View all"
        @click="changeProvidersVisible(true)"
      >
        <template #icon>
          <SvgIcon
            class="mr-[5px]"
            icon="iconShow"
          ></SvgIcon>
        </template>
      </Button>
      <Button
        class="min-w-[49%] md:min-w-[84px] btn-default-small flex flex-wrap z-[999] relative btn-small btn-default-yellow"
        text="Hide all"
        @click="changeProvidersVisible(false)"
      >
        <template #icon>
          <SvgIcon
            class="mr-[5px]"
            icon="iconHide"
          ></SvgIcon>
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
import ProvidersTableFilter from './ProvidersTableFilter/ProvidersTableFilter';
import Button from '@/components/_common/FormElements/Button/Button';
import SvgIcon from "@/components/_common/SvgIcon";
export default {
  components: {
    ProvidersTableFilter,
    Button,
    SvgIcon,
  },
  data() {
    return {      
      name: '',
    };
  },
  methods: {
    changeProvidersVisible(visible) {
      this.$emit('changeProvidersVisible', visible)
    },
    search() {
      this.$emit('search', this.name)
    },
    changeFilters(filters) {
      this.$emit('changeFilters', filters)
    },
  }
};
</script>

<style lang="scss">
@import './ProvidersTableHeader.scss';
</style>